import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, RoutesRecognized } from '@angular/router';
import { AppHelperService, IPageMeta } from '@ztarmobile/zwp-service';
import { ActionsAnalyticsService } from '@ztarmobile/zwp-service-backend';
import { filter, takeWhile } from 'rxjs/operators';
import { AppState } from './app.service';
import { AuthHttp } from '@ztarmobile/zwp-services-auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ACCOUNT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, CHECKOUT_ROUTE_URLS, LOGIN_ROUTE_URLS, MIGRATION_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS, SUPPORT_ROUTE_URLS } from './app.routes.names';
import { ModalHelper } from 'src/services/modal-helper.service';
import { Meta } from '@angular/platform-browser';
import { getAuth } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Freeup';
  public maintenanceExists = false;
  public loadMinHeight = true;
  public enableIap = true;
  public removeMinHeight = true;
  public isAlerPopupDisplayed;
  public INACTIVITY_WARNING_TIME = 25 * 60;

  private isBackButton: boolean;
  private alive = true;
  private timeLeft;
  private lastActivityTime;
  private idleInterval: any;

  constructor(public appState: AppState, private location: Location, private appHelper: AppHelperService,
              private actionsAnalyticsService: ActionsAnalyticsService, private router: Router, private meta: Meta,
              private authHttp: AuthHttp,private angularAuthService: AngularFireAuth, private modalHelper: ModalHelper,
              private dialogRef: MatDialog) {
  }

  ngOnInit(): void {
    if(!localStorage.getItem('googleToken') && !environment.production && this.enableIap) {
      this.modalHelper.showGoogleLoginsModal();
    } else if (!!localStorage.getItem('googleToken') && !!this.checkGoogleTokenExpirey(localStorage.getItem('googleToken')) && !environment.production && this.enableIap) {
      localStorage.removeItem('googleToken');
        // call modal again to login
        this.modalHelper.showGoogleLoginsModal();
    } 
    this.subscribeRouterEvents();
    this.isIE();
    this.isSamsungBrowser();
    this.authHttp.setLanguage('en');
    this.checkIfMaintenanceExist();
    this.checkUserActivity();

  }

  ngAfterViewInit(): void {
    if (!!this.router.events) {
      this.router.events.pipe(filter((event) => event instanceof RoutesRecognized)
        , takeWhile(() => this.alive))
        .subscribe((event: RoutesRecognized) => {
          const route = this.getLastChildOnTreeOfActivatedRoute(event.state);
          if (
            event.state.url.includes(LOGIN_ROUTE_URLS.BASE) === true ||
            event.state.url.includes(ACCOUNT_ROUTE_URLS.BASE) === true ||
            event.state.url.includes(SHOP_ROUTE_URLS.BASE) === true ||
            event.state.url.includes(PLANS_SHOP_ROUTE_URLS.BASE) === true ||
            event.state.url.includes(CHECKOUT_ROUTE_URLS.BASE) === true ||
            event.state.url.includes(MIGRATION_ROUTE_URLS.BASE) === true ||
            route.routeConfig.path.indexOf('**') > -1
          ) {
            this.loadMinHeight = false;
          }
          else {
            this.loadMinHeight = true;
          }
          if(
            event.state.url.includes(ACTIVATION_ROUTE_URLS.BASE) ||
            event.state.url.includes(`${PLANS_SHOP_ROUTE_URLS.BASE}/${PLANS_SHOP_ROUTE_URLS.CHANGE_PLAN}`)
          ) {
            this.removeMinHeight = true;
          } else {
            this.removeMinHeight = false;
          }
          this.checkRouterDataChanges(route);
      });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.idleInterval) {
      clearInterval(this.idleInterval);
    }
    window.removeEventListener('mousemove', this.onUserActivity.bind(this));
    window.removeEventListener('keypress', this.onUserActivity.bind(this));
    window.removeEventListener('scroll', this.onUserActivity.bind(this));
  }

 public checkGoogleTokenExpirey(token): boolean {
    const decodedToken: any = jwtDecode(token);
    const expirationTime = decodedToken.exp;
     // Current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);
     // return true or false if token has expired
    return expirationTime < currentTime ;
  }

  public isIE(): boolean {
    const match = navigator.userAgent.search(/(?:MSIE|Trident\/.*; rv:)/);
    let isIE = false;
    if (match !== -1) {
      isIE = true;
    }
    return isIE;
  }

  public isSamsungBrowser(): boolean{
    const isSamsungBrowser = (navigator.userAgent.includes('SamsungBrowser') ||
    navigator.userAgent.includes('Samsung Browser')) && parseFloat(navigator.appVersion.substr(0, 3)) <= 4.0;
    return isSamsungBrowser;
  }

  private checkRouterDataChanges(route: ActivatedRouteSnapshot): void {
    const data: IPageMeta = route.data as IPageMeta;
    this.appHelper.setPageTitle(data.title);
    if (!!data.description) {
      this.appHelper.setPageDescription(data.description);
    }
  }

  // workaround since ActivatedRoute is not working.
  private getLastChildOnTreeOfActivatedRoute(snapshot: RouterStateSnapshot): ActivatedRouteSnapshot {
    let parent = snapshot.root;
    while (!!parent.firstChild) {
      parent = parent.firstChild;
    }
    return parent;
  }

  private handleRouterEventChange(event: NavigationEnd): void {
    if (event instanceof NavigationEnd) {
      this.meta.updateTag({ property: 'og:url', content: window.location.href });
      this.meta.updateTag({ name: 'twitter:url', content: window.location.href });
      this.actionsAnalyticsService.trackPageView(event);
      this.location.subscribe((e) => {
        // this is fired when user click back or forward of browser
        if (e.pop) {
          this.isBackButton = true;
          setTimeout(() => this.isBackButton = false, 500);
        }
      });
          document.body.scrollTop = 0;
          window.scroll(0, 0);
    }
  }

  private subscribeRouterEvents(): void {
    if (!!this.router.events) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)
        , takeWhile(() => this.alive))
        .subscribe((event: NavigationEnd) => {
          this.handleRouterEventChange(event);
        });
    }
  }

  private checkIfMaintenanceExist(): void {
    const maxDate = new Date('February 06, 2022 02:00:00 GMT-6:00');
    const displayDate = new Date('February 04, 2022 03:00:00 GMT-6:00');
    const todayDate = new Date();
    if(todayDate < maxDate && todayDate >= displayDate){
      this.maintenanceExists = true;
    }
  } 

  private logout(): void {
    this.clearInactivityCheck();
    this.appState.clearSessionStorage();
    this.angularAuthService.signOut().then(() => {
      this.appState.userLoggedIn.next(undefined);
      this.dialogRef.closeAll();
      this.router.navigate([ROUTE_URLS.HOME]);
    })
  }
  private onUserActivity(): void {
    // Set userActive to true and reset the last activity time
    this.lastActivityTime = Date.now();  // Update the last activity time

    // Stop the counter (this only stops the countdown, not the timer)
    if (this.timeLeft < this.INACTIVITY_WARNING_TIME) {
      this.timeLeft = this.INACTIVITY_WARNING_TIME; // Reset time left
    }
  }
  // Checks for user activity and starts the inactivity timer
  private checkUserActivity(): void {
    getAuth().onAuthStateChanged(user => {
      if (!!user && !user?.isAnonymous) {
        this.timeLeft = this.INACTIVITY_WARNING_TIME; // Reset timer to 25 minutes
        this.lastActivityTime = Date.now(); // Track the initial time
        this.clearInactivityCheck();
        // Start interval to check inactivity
        this.idleInterval = setInterval(() => {
          const currentTime = Date.now();
          // total time that has passed since the user’s last activity.
          const elapsedTime = Math.floor((currentTime - this.lastActivityTime) / 1000); // In seconds
          // If user has been inactive for more than 25 minutes, show popup
          if (elapsedTime >= this.INACTIVITY_WARNING_TIME && !this.isAlerPopupDisplayed) {
            this.showInactivityPopup();
          }
        }, 1000); // Check every second
      } else {
        // If no user is logged in, clear interval and remove event listeners
        this.clearInactivityCheck();
      }
    });

    // Add event listeners for user activity
    window.addEventListener('mousemove', this.onUserActivity.bind(this));
    window.addEventListener('keypress', this.onUserActivity.bind(this));
    window.addEventListener('scroll', this.onUserActivity.bind(this));
  }

  private clearInactivityCheck(): void {
    // Clear the interval and remove event listeners when user logs out or session ends
    if (this.idleInterval) {
      clearInterval(this.idleInterval);
      this.dialogRef.closeAll();
    }
    window.removeEventListener('mousemove', this.onUserActivity.bind(this));
    window.removeEventListener('keypress', this.onUserActivity.bind(this));
    window.removeEventListener('scroll', this.onUserActivity.bind(this));
  }

  private resetVariables(): void {
    this.timeLeft = this.INACTIVITY_WARNING_TIME;
    this.isAlerPopupDisplayed = false;
    this.checkUserActivity();
  }
  private refreshUserToken(): void {
    this.resetVariables();
    window.location.reload();
  }
   // Shows inactivity warning popup
   private showInactivityPopup(): void {
    this.isAlerPopupDisplayed = true;
    const customHTML = `<p class="desc">You have been inactive for a while. For your security,
              please choose whether to stay <b>signed in</b> or to <b>log out.</b></p>
              <p class="desc last">Otherwise, you will be logged out <b>automatically.</b></p>`;
   this.modalHelper.showAlertSecurityModal('Your session is about to end','Stay Connected','Log out', true, 'timer-alert-modal', customHTML).afterClosed().subscribe((res) => {
        this.isAlerPopupDisplayed = false; // Reset the popup flag
        if (res === 'logout') {
          this.logout();
        } else {
          // Refresh token and reset timers
          this.refreshUserToken();
        }
      });
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent) {
      this.logout();
  }
}
